import instance from '../interceptors/apiInterceptor';

export const createPayments = async data => {
  try {
    const response = await instance.post('/paymentsHandler', data);
    return response.data;
  } catch (error) {
    throw new Error('An error occurred while creating the user.');
  }
};

export const getPayments = async (id) => {
  try {
    const response = await instance.get(`/getPayments/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPaymentIntent = async (amount) => {
  try {
    const response = await instance.post('/getPaymentIntentHandler', JSON.stringify({ amount }) );
    return response.data;
  } catch (error) {
    throw error;
  }
};
