import React, { useState, useEffect } from "react";
import { Box, Tab } from "@mui/material";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import UserForm from "./UserForm";
import PurchaseHistory from "./PurchaseHistory";

const EditForm = () => {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="edit user details and see previous purchases" centered>
            <Tab label="Previous purchases" value="1" />
            <Tab label="Edit personal details" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1"><PurchaseHistory /></TabPanel>
        <TabPanel value="2"><UserForm /></TabPanel>
      </TabContext>
    </Box>
  );
};

export default EditForm;
