import instance from '../interceptors/apiInterceptor';

export const createProduct = async data => {
  try {
    const response = await instance.post('/productsHandler', data);
    return response.data;
  } catch (error) {
    throw new Error('An error occurred while creating the user.');
  }
};

export const getProduct = async (id) => {
  try {
    const response = await instance.get(`/getProduct/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getProducts = async () => {
  try {
    const response = await instance.get('/productsHandler');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addProducts = async data => {
  try {
    const response = await instance.post('/addProductsHandler', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
