import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { getPayments } from "api/payments";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ReactImageMagnify from "react-image-magnify";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const PurchaseHistory = () => {
  const [response, setResponse] = useState(null);
  const dispatch = useDispatch();
  const id = useSelector(state => state.auth.id);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    let response = await getPayments(id);
    setResponse(response);
  };

  return (
    response && (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Image</StyledTableCell>
              <StyledTableCell align="left">Date</StyledTableCell>
              <StyledTableCell align="left">Description</StyledTableCell>
              <StyledTableCell align="left">Qty</StyledTableCell>
              <StyledTableCell align="left">Price</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {response.data.map((item, index) =>
              JSON.parse(item.Cart).map((cartItem, cartIndex) => (
                <StyledTableRow key={cartIndex}>
                  <StyledTableCell align="left">
                    {" "}
                    <ReactImageMagnify
                      {...{
                        smallImage: {
                          alt: "Product",
                          isFluidWidth: false,
                          isActivatedOnTouch: true,
                          src: cartItem?.item.MainImageUrl,
                          height: 70,
                          width: 70,
                        },
                        largeImage: {
                          src: cartItem?.item.MainImageUrl,
                          width: 600,
                          height: 1800,
                        },
                        enlargedImagePosition: "over",
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    {new Date(item.Date).toLocaleString('en-AU')}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {cartItem.item.ProductDescription}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {cartItem.item.Quantity}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    ${cartItem.item.Price}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    )
  );
};

export default PurchaseHistory;
