import instance from '../interceptors/apiInterceptor';

export const getShippingCost = async data => {
  try {
    const response = await instance.post('/shippingCost', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
