import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addFilter,
  removeFilter
} from "../store/reducers/searchSlice";
import {
  ButtonBase,
  Typography,
  Hidden,
  AppBar,
  Stack,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Badge,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import logo from "../logo/logo.jpg"; // Import the logo image
import { Link } from "react-router-dom";
import { logout } from "store/reducers/authSlice";
import EditIcon from "@mui/icons-material/Edit";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import LaptopIcon from "@mui/icons-material/Laptop";
import SmartphoneIcon from "@mui/icons-material/Smartphone";

// Import the makeStyles function
import { makeStyles } from "@mui/styles";

// Create a new style using makeStyles
const useStyles = makeStyles(theme => ({
  link: {
    color: "#0077be",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
    fontSize: 20,
    paddingLeft: 2,
    [theme.breakpoints.down("lg")]: {
      fontSize: 16,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
    },
  },
  drawerLink: {
    color: "#0077be",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
    fontSize: 12,
  },
}));

export const Header = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const activeFilters = useSelector(state => state.search?.activeFilters);
  const customerType = useSelector(state => state.auth.customerType);
  let isLoggedIn = useSelector(state => state.auth.idToken);
  let userName = useSelector(state => state.auth.name);
  const [open, setOpen] = useState(false);
  const [cartItems, setCartItems] = useState(0);
  const cart = useSelector(state => state?.cart?.items);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setIsMenuOpen(true);
  };

  const handleDrawerClose = () => {
    setIsMenuOpen(false);
  };

  const handleFilterChange = filter => {
    if (filter?.toUpperCase() === "ALL") {
      dispatch(removeFilter("Computers - Laptop Computers"));
      dispatch(removeFilter("Electronics - Cell Phones and Accessories"));
      dispatch(removeFilter("Computers - Computer Components"));
      return;
    }
    if (activeFilters.includes(filter)) {
      // dispatch(removeFilter(filter)); // Dispatch removeFilter action
    } else {
      dispatch(addFilter(filter)); // Dispatch addFilter action
    }
  };

  const handleToLocation = text => {
    if (text === "Home") {
      return "";
    } else if (
      text === "Laptops" ||
      text === "Phones" ||
      text === "Accessories"
    ) {
      return "#";
    } else {
      return text.toLowerCase();
    }
  };

  // Use the new style
  const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      const totalItems = Object.values(cart).reduce(
        (total, { quantity }) => total + quantity,
        0
      );
      setCartItems(totalItems);
    };
    fetchData();
  }, [cart]);

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "#f0f8ff", minHeight: "110px" }}
    >
      <Toolbar sx={{ alignItems: "center", marginLeft: -2 }}>
        <Link to="/" >
          <img src={logo} alt="Logo" height="110" />
        </Link>
        <Typography component="div" sx={{ flexGrow: 1, marginLeft: 2 }}>
          <nav>
            <Stack
              direction="row"
              spacing={3}
              alignItems="centre"
              justifyContent="space-between"
            >
              {/* <Link
                  className={classes.link}
                  to="#"
                  onClick={() => handleFilterChange("ALL")}
                >
                  ALL
                </Link> */}
              {/* </Hidden> */}
              <Link
                className={classes.link}
                to="#"
                onClick={() =>
                  handleFilterChange("Computers - Laptop Computers")
                }
              >
                <IconButton
                  edge="start"
                  color="primary"
                  aria-label="laptop"
                  sx={{ color: "#0077be", marginTop: { xs: 0, md: -1 } }}
                >
                  <LaptopIcon />
                </IconButton>
              </Link>
              <Link
                className={classes.link}
                to="#"
                onClick={() =>
                  handleFilterChange(
                    "Electronics - Cell Phones and Accessories"
                  )
                }
              >
                <IconButton
                  edge="start"
                  color="primary"
                  aria-label="laptop"
                  sx={{ color: "#0077be", marginTop: { xs: 0, md: -1 } }}
                >
                  <SmartphoneIcon />
                </IconButton>
              </Link>
              <Hidden mdDown>
                <Link className={classes.link} to="/">
                  HOME
                </Link>
                {customerType && (
                  <Link className={classes.link} to="/upload">
                    UPLOAD
                  </Link>
                )}

                <Link className={classes.link} to="/about">
                  ABOUT
                </Link>
                <Link className={classes.link} to="/contact">
                  CONTACT
                </Link>
                <Link className={classes.link} to="/testimonials">
                  TESTIMONIALS
                </Link>

                {isLoggedIn && (
                  <Link
                    className={classes.link}
                    to="/"
                    onClick={() => {
                      isLoggedIn = false;
                      dispatch(logout());
                    }}
                  >
                    LOGOUT
                  </Link>
                )}
                {isLoggedIn && (
                  <Link
                    className={classes.link}
                    to="/edit"
                    // onClick={() => dispatch(logout())}
                  >
                    {" "}
                    <IconButton sx={{ color: "#0077be", marginTop: -1 }}>
                      <EditIcon />
                    </IconButton>
                  </Link>
                )}

                {!isLoggedIn && (
                  <Link className={classes.link} to="/login">
                    LOGIN
                  </Link>
                )}
              </Hidden>
              <Link className={classes.link} to="/cartWrapper">
                <Hidden mdDown>
                  <span>Hi {userName}</span>
                </Hidden>
                <IconButton
                  sx={{ color: "#0077be", marginTop: { xs: 0, md: -1 } }}
                >
                  <Badge badgeContent={cartItems} color="secondary">
                    <ShoppingCartIcon />
                  </Badge>
                </IconButton>
              </Link>
              <Hidden mdUp>
                <Link className={classes.drawerLink} to="#">
                  <IconButton
                    edge="start"
                    color="primary"
                    aria-label="menu"
                    onClick={handleDrawerOpen}
                  >
                    <MenuIcon />
                  </IconButton>
                </Link>
                <Drawer
                  anchor="right"
                  open={isMenuOpen}
                  onClose={handleDrawerClose}
                >
                  <List>
                    <ListItem>Hi {userName}</ListItem>
                    {[
                      "Home",
                      "Laptops",
                      "Phones",
                      "Accessories",
                      "About",
                      "Contact",
                      "Testimonials",
                      isLoggedIn ? "Logout" : "Login",
                    ].map(text => (
                      <ListItem key={text}>
                        <ButtonBase onClick={handleDrawerClose}>
                          <Link
                            to={handleToLocation(text)}
                            className={classes.link}
                          >
                            <ListItemText
                              primary={text}
                              onClick={() => {
                                if (text === "Logout") {
                                  dispatch(logout());
                                  isLoggedIn = false;
                                }
                                if (text === "Laptops") {
                                  handleFilterChange(
                                    "Computers - Laptop Computers"
                                  );
                                }
                                if (text === "Phones") {
                                  handleFilterChange(
                                    "Electronics - Cell Phones and Accessories"
                                  );
                                }
                                if (text === "Accessories") {
                                  handleFilterChange(
                                    "Computers - Computer Components"
                                  );
                                }
                              }}
                            />
                          </Link>
                        </ButtonBase>
                      </ListItem>
                    ))}
                  </List>
                </Drawer>
              </Hidden>
            </Stack>
          </nav>
        </Typography>
      </Toolbar>
    </AppBar>
  );
};
