import { createPayments } from "api/payments";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { emptyCart } from "store/reducers/cartSlice";
import { useNavigate } from "react-router-dom";
import { Button, Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.secondary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
  },
}));

const PaymentResult = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const paymentResult = urlParams.get("redirect_status");
  const paymentIntent = urlParams.get("payment_intent");
  const cart = useSelector(state => state?.cart);
  const address = useSelector(state => state?.address?.items);
  const [status, setStatus] = useState("default");
  const [totalPrice, setTotalPrice] = useState(0);
  const [dataSaved, setDataSaved] = useState(false);
  const [salesData, setSalesData] = useState(null);

  useEffect(() => {
    // Check if cart is empty
    if (Object.keys(cart.items).length === 0) {
      return;
    }

    let currentTotalPrice = Object.values(cart?.items).reduce(
      (total, { item, quantity }) => total + item.Price * quantity,
      0
    );

    if (+cart?.shippingCost > 0) {
      currentTotalPrice = currentTotalPrice + +cart?.shippingCost;
    }

    if (currentTotalPrice > 0 && totalPrice !== currentTotalPrice) {
      if (totalPrice === currentTotalPrice) {
        return;
      }

      const cartItems = [];
      setTotalPrice(currentTotalPrice);

      Object.values(cart?.items).map(({ item, quantity }) =>
        cartItems.push({
          item,
          quantity,
        })
      );

      setSalesData({
        description: `${cartItems[0].item.Id}`,
        cart: `${JSON.stringify(cartItems)}`,
        address,
        token: paymentIntent,
        shipping: cart?.shippingCost,
        amount: currentTotalPrice,
      });
    }
  }, [cart, totalPrice, address, paymentIntent]);

  useEffect(() => {
    const fetchData = async () => {
      if (!salesData) {
        return;
      }
      try {
        const data = await createPayments(salesData);
        if (data.status === "succeeded") {
          setStatus("complete");
        } else {
          setStatus("error");
        }
        setDataSaved(true);
        dispatch(emptyCart());
      } catch (error) {
        console.error(error);
        setStatus("error");
      }
    };
    fetchData();
  }, [salesData]);

  return (
    <Box p={10}>
      {paymentResult === "succeeded" ? (
        <>
          <Typography
            color="primary"
            variant="body1"
            style={{ fontWeight: "bold" }}
          >
            Congratulations!! Your order is on its way.
          </Typography>

          <Typography>
            An amount of{" "}
            {totalPrice?.toLocaleString("en-AU", {
              style: "currency",
              currency: "AUD",
            })}{" "}
            was charged to your account. Your order is being prepared for
            delivery. We have sent you an email with the details.
          </Typography>
        </>
      ) : (
        <>
          <Typography>Payment failed.</Typography>
          <StyledButton
            variant="contained"
            onClick={() => navigate(`/cartWrapper`)}
          >
            Try again
          </StyledButton>
        </>
      )}
      <Box mt={2}>
        <StyledButton variant="contained" onClick={() => navigate(`/`)}>
          Back to Shopping
        </StyledButton>
      </Box>
    </Box>
  );
};

export default PaymentResult;
