import React from "react";
import { Box, Grid, Breadcrumbs } from "@mui/material";

import { Filters } from "./Filters";

import { Shopping } from "./Shopping";
import CartList from "./CartList";

function Home() {
  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Breadcrumbs />
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} sm={12} md={9} lg={10}>
            <Shopping />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={2}>
            <CartList />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Home;
