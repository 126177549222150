import React from "react";
import {
  Button,
  TextField,
  Grid,
  List,
  ListItem,
  ListItemText,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
} from "@mui/material";

const TermsAndConditions = () => {
  return (
    <div>
      <h1>Terms and Conditions - Purchase Paradise</h1>
      <p>Last updated: (2023)</p>
      <p>
        Welcome to Purchase Paradise, a service provided by Apricot Software Pty
        Limited ("us", "we", or "our").
      </p>

      <h2>1. Acceptance of Terms</h2>
      <p>
        By using our website, you agree to the terms and conditions outlined in
        this agreement. If you disagree with any part of the terms, then you may
        not access the service.
      </p>

      <h2>2. Online Store Terms</h2>
      <p>
        By agreeing to these Terms and Conditions, you represent that you are at
        least the age of majority in your state or province of residence, or
        that you are the age of majority in your state or province of residence
        and you have given us your consent to allow any of your minor dependents
        to use this site.
      </p>

      <h2>3. Modifications to the Service and Prices</h2>
      <p>
        Prices for our products are subject to change without notice. We reserve
        the right at any time to modify or discontinue the Service (or any part
        or content thereof) without notice at any time.
      </p>

      <h2>4. Products or Services</h2>
      <p>
        Certain products or services may be available exclusively online through
        the website. These products or services may have limited quantities and
        are subject to return or exchange only according to our Return Policy.
      </p>

      <h2>5. Personal Information</h2>
      <p>
        Your submission of personal information through the store is governed by
        our Privacy Policy.
      </p>

      <h2>6. Governing Law</h2>
      <p>
        These Terms and Conditions and any separate agreements whereby we
        provide you Services shall be governed by and construed in accordance
        with the laws of Australia.
      </p>

      <h2>7. Changes to Terms and Conditions</h2>
      <p>
        You can review the most current version of the Terms and Conditions at
        any time at this page. We reserve the right, at our sole discretion, to
        update, change or replace any part of these Terms and Conditions by
        posting updates and changes to our website.
      </p>

      <h2>8. Contact Information</h2>
      <p>
        Questions about the Terms and Conditions should be sent to us at
        support@purchaseparadise.com.au
      </p>
    </div>
  );
}

export default TermsAndConditions;
