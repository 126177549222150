import React, { useState, useEffect } from "react";
import { TextField, Button, Box } from "@mui/material";
import { getUser, updateUser } from "../api/user";

const UserForm = () => {
  const [user, setUser] = useState({});
  const [updatedUser, setUpdatedUser] = useState({});

  useEffect(() => {
    getUser().then(data => {
      setUser(data);
      setUpdatedUser(data);
    });
  }, []);

  const handleChange = event => {
    setUpdatedUser({
      ...updatedUser,
      UserAttributes: updatedUser?.UserAttributes?.map(attribute =>
        attribute.Name === event.target.name
          ? { ...attribute, Value: event.target.value }
          : attribute
      ),
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    updateUser({
      "address": getAttributeValue('address'),
      "name": getAttributeValue('name'),
      "family_name": getAttributeValue('family_name'),
      "phone_number": getAttributeValue('phone_number'),
      "website": getAttributeValue('website'),
      "birthdate": getAttributeValue('birthdate')
    }).then(() => {
      setUser(updatedUser);
    });
  };

  const getAttributeValue = name =>
    updatedUser?.UserAttributes &&
    updatedUser?.UserAttributes?.find(attribute => attribute.Name === name)
      ?.Value;

  return (
    <div style={{ paddingTop: '16px' }}>
      <h2>Edit your details</h2>
      <form onSubmit={handleSubmit}>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{ width: "80%", margin: "auto" }}
        >
          <TextField
            label="Address"
            name="address"
            value={getAttributeValue("address")}
            onChange={handleChange}
            color="primary"
          />
          <TextField
            label="Name"
            name="name"
            value={getAttributeValue("name")}
            onChange={handleChange}
            color="primary"
          />
          <TextField
            label="Family Name"
            name="family_name"
            value={getAttributeValue("family_name")}
            onChange={handleChange}
            color="primary"
          />
          <TextField
            label="Phone Number"
            name="phone_number"
            value={getAttributeValue("phone_number")}
            onChange={handleChange}
            color="primary"
          />
          <TextField
            label="Birthdate"
            name="birthdate"
            type="date"
            value={getAttributeValue("birthdate")}
            onChange={handleChange}
            color="primary"
          />
          <Button type="submit" variant="contained" color="secondary">
            Update
          </Button>
        </Box>
      </form>
    </div>
  );
};

export default UserForm;
