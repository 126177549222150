import React, { useState } from "react";
import { Card, CardMedia, Box, IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ReactImageMagnify from "react-image-magnify";


const ProductDisplay = ({ product, height, width }) => {
  const [mediaIndex, setMediaIndex] = useState(0);
  const handleScroll = event => {
    if (event.deltaY > 0) {
      setMediaIndex(prevIndex => (prevIndex + 1) % product.media.length);
    } else {
      setMediaIndex(prevIndex =>
        prevIndex === 0 ? product.media.length - 1 : prevIndex - 1
      );
    }
  };

  const handlePrevClick = () => {
    setMediaIndex(prevIndex =>
      prevIndex === 0 ? product.media.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setMediaIndex(prevIndex => (prevIndex + 1) % product.media.length);
  };

  return (
    <Card
      onWheel={handleScroll}
      sx={{
        boxShadow: "none",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          boxShadow: "none",
          paddingTop: "5px",
          maxWidth: "350px",
          margin: "auto",
        }}
      >
        <IconButton
          color="primary"
          aria-label="previous picture"
          onClick={handlePrevClick}
          sx={{ fontSize: 36, padding: 0 }}
        >
          <ChevronLeftIcon />
        </IconButton>

        {product.media.map((mediaItem, index) => (
          <Box
            key={index}
            sx={{
              display: mediaIndex === index ? "block" : "none",
              boxShadow: "none",
            }}
          >
            {mediaItem.type === "img" ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <ReactImageMagnify
                  {...{
                    smallImage: {
                      alt: "Product",
                      isFluidWidth: false,
                      isActivatedOnTouch: true,
                      src: mediaItem.url,
                      height: height ?? 200,
                      width: width ?? 240,
                      marginTop: { xs: 0, sm: -1 }
                    },
                    largeImage: {
                      src: mediaItem.url,
                      width: 1200,
                      height: 1800,
                    },
                    enlargedImagePosition: "over",
                  }}
                />
              </div>
            ) : (
              <CardMedia
                component={mediaItem.type}
                controls={mediaItem.type === "video"}
                sx={{
                  objectFit: "contain",
                  width: "100%",
                  height: "140px",
                }}
                src={mediaItem.url}
              />
            )}
          </Box>
        ))}
        <IconButton
          color="primary"
          aria-label="next picture"
          onClick={handleNextClick}
          sx={{ fontSize: 36, padding: 0 }}
        >
          <ChevronRightIcon />
        </IconButton>
      </Box>
    </Card>
  );
};

export default ProductDisplay;
