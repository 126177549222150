import instance from '../interceptors/apiInterceptor';

export const getUser = async () => {
  try {
    const response = await instance.get('/getUser');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateUser = async (personalDetails) => {
  try {
    const response = await instance.post('/updateUser', JSON.stringify(personalDetails));
    return response.data;
  } catch (error) {
    throw error;
  }
};
