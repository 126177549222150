import React from 'react';

const ForSale = () => {
  return (
    <span className="sale" style={{ marginRight: '5px', fontSize: '16px', color: 'Red' }}>
      <span>Sale!</span>
    </span>
  );
};

export default ForSale;
