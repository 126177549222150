import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { AddressElement, Elements } from "@stripe/react-stripe-js";
import { addToAddress, setAddress } from "../store/reducers/addressSlice";
import { Typography } from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";

const environment = process.env.NODE_ENV; // 'dev' or 'prod'
const testKey = "pk_test_51NaurBIoxGtsLS1a7voDcaYIIkQSGiiXmxExNH2exwi7nS4xCvUo8kN0uCVmvicdhFCNC7LVP1inHfRtB3TfLpy100spdfmzlW";
const liveKey = "pk_live_51NaurBIoxGtsLS1aXwQrShQUcmGBNIKDxhM5Z7f31Gvp2EWw8AF96SAxXnWp9himmEVo9r4o2r2d1dHA7EtpYtNc00KOLQKkJn";
const stripePromise = loadStripe(environment === 'production' ? liveKey : testKey);

const ShippingAddress = () => {
  const dispatch = useDispatch();
  const address = useSelector(state => state?.address?.items);
  const addressElementOptions = {
    appearance: {
      theme: "stripe",
      variables: {
        colorPrimary: "#0570de",
        colorBackground: "#ffffff",
        colorText: "#30313d",
        colorDanger: "#df1b41",
        fontFamily: "Ideal Sans, system-ui, sans-serif",
        spacingUnit: "2px",
        borderRadius: "4px",
      },
    },
    mode: "shipping",
    // autocomplete: true
  };

  const handleAddressChange = event => {
    if (event.complete) {
      dispatch(addToAddress(event.value.address));
    }
  };

  return (
    <>
      <Elements stripe={stripePromise}>
        <AddressElement
          id="address-element"
          options={addressElementOptions}
          onChange={handleAddressChange}
          // defaultValues={address}
          autocomplete
        />
      </Elements>
    </>
  );
};

export default ShippingAddress;
