async function decodeReadableStream(stream) {
    const reader = stream.getReader();
    const decoder = new TextDecoder();
    let result = '';

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      result += decoder.decode(value);
    }

    return JSON.parse(result);
  }

  async function formatResponse(response) {
    const responseStream = await decodeReadableStream(response.body);
    return JSON.parse(responseStream);
  }

  function getEnvironmentValue(localValue, devValue, prodValue) {
    if (process.env.NODE_ENV === 'development') {
      return devValue;
    } else if (process.env.NODE_ENV === 'production') {
      return prodValue;
    } else {
      return localValue;
    }
  }
  

  function getEnvironmentUrlValueWithPath(path) {
    const currentProtocol = window.location.protocol;
    const currentHostname = window.location.hostname;
    const currentPort = window.location.port;
    return `${currentProtocol}//${currentHostname}${currentPort ? ':' + currentPort : ''}/${path}`;
  }
  

  export { decodeReadableStream, formatResponse, getEnvironmentValue, getEnvironmentUrlValueWithPath };
