import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function About() {
  return (
    <div>
    <Box sx={{ textAlign: 'justify', marginTop: 10, marginLeft: 20, marginRight: 20 }}>
      <Typography color='primary' variant="h1" sx={{ textAlign: 'center', marginBottom: 5 }}>About Us</Typography>
      <Typography paragraph>
        Welcome to PurchaseParadise.com.au, your one-stop online shopping destination. We are a proudly Australian-owned and operated business, under the umbrella of Apricot Software Pty Ltd.
        Apricot Software Pty Ltd, founded in 2018, is a dynamic and innovative company registered with the Australian Securities and Investments Commission (ASIC). We are committed to upholding the highest standards of business conduct and ethics.
      </Typography>
      <Typography paragraph>
        At PurchaseParadise.com.au, we strive to provide our customers with a wide range of products at competitive prices. We believe in the power of technology to simplify shopping, making it more convenient, enjoyable, and accessible for everyone.
      </Typography>
      <Typography paragraph>
        Our team is dedicated to providing excellent customer service and ensuring a seamless shopping experience from browsing to delivery. We continually update our product offerings to bring you the latest trends and innovations. Thank you for choosing PurchaseParadise.com.au. We look forward to serving you!
      </Typography>
      <Typography>ABN: 68 629 081 3491</Typography>
    </Box>
        <Box sx={{ mt: 5, mb: 5 }}>
        <div>
        <Typography color='primary' variant="h2" sx={{ textAlign: 'center', marginBottom: 5 }}>Our Partnerships</Typography>
          <a
            href="#"
            target="_blank" rel="noreferrer"
          >
            <img src="hp.png" alt="LinkedIn" width="512" height="256" style={{ marginRight: '10px' }} />
          </a>
  
          <a
            href="#"
            target="_blank" rel="noreferrer"
          >
            <img src="lenovo.png" alt="Facebook" width="256" height="256" style={{ marginRight: '10px' }} />
          </a>
  
          <a
            href="#"
            target="_blank" rel="noreferrer"
          >
            <img src="samsung.png" alt="Instagram" width="256" height="256" />
          </a>
        </div>
  
      </Box>
      </div>
  );
}

export default About;
