import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Button, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { getEnvironmentUrlValueWithPath } from "api/util";

const Cart = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const cart = useSelector(state => state?.cart?.items);
  const address = useSelector(state => state?.address?.items);
  const [status, setStatus] = useState("default");
  const [name, setName] = useState("zz");
  const [checked, setChecked] = useState(true);

  const handleChange = event => {
    setChecked(event.target.checked);
  };

  const handleSubmit = async e => {
    // e.preventDefault();

    if (!stripe || !elements || !address || status === "submitting") {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setStatus("submitting");
    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: getEnvironmentUrlValueWithPath("paymentResult"),
      },
    });

    // This point will only be reached if there is an immediate error when confirming payment
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
    setStatus('default');
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  useEffect(() => {
    if (status === "complete") {
      setMessage("Payment successful!");
    }
  }, [status]);

  return (
    <div style={{ paddingTop: "18px" }}>
      <div>
        <LinkAuthenticationElement
          id="link-authentication-element"
          onChange={e => setEmail(e.target?.value)}
        />
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={handleChange}
              name="termsCheckbox"
              color="primary"
            />
          }
          label={
            <Typography color="primary">
              I agree to the {" "}
              <a href="/termsConditions" target="_blank" rel="noopener noreferrer">
                Terms and Conditions
              </a>
            </Typography>
          }
        />
        <Button
          variant="contained"
          onClick={() => handleSubmit()}
          disabled={
            isLoading ||
            !stripe ||
            !elements ||
            status === "submitting" ||
            !checked
          }
        >
          {isLoading ? (
            <div className="spinner" id="spinner"></div>
          ) : status === "submitting" ? (
            "Submitting"
          ) : (
            "Submit Order"
          )}
        </Button>
        {status === "error" && (
          <div>
            There was an error processing your payment. Please try again.
          </div>
        )}
        <br></br>
        {message && <div id="payment-message">{message}</div>}
      </div>
    </div>
  );
};

export default Cart;
