import instance from '../interceptors/apiInterceptor';

export const getToken = async () => {
  try {
    const response = await instance.get('/getIngramMicroToken');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const batchProductUpdate = async data => {
  try {
    const response = await instance.post('/batchProductUpdate', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
