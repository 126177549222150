import React from "react";
import { Typography, Box } from "@mui/material";
import linkedinLogo from './../images/linkedin.png';
import facebookLogo from  './../images/facebook.png';
import instagramLogo from  './../images/instagram.png';

export function Footer() {
  return (
    <Box sx={{ mt: 2 }}>
      <div>
        <a
          href="https://www.linkedin.com/company/99373129/admin/feed/posts/"
          target="_blank" rel="noreferrer"
        >
          <img src={linkedinLogo} alt="LinkedIn" width="32" height="32" style={{ marginRight: '10px' }} />
        </a>

        <a
          href="https://www.facebook.com/profile.php?id=61555680192916"
          target="_blank" rel="noreferrer"
        >
          <img src={facebookLogo} alt="Facebook" width="32" height="32" style={{ marginRight: '10px' }} />
        </a>

        <a
          href="https://www.instagram.com/sales_purchase_paradise/"
          target="_blank" rel="noreferrer"
        >
          <img src={instagramLogo} alt="Instagram" width="32" height="32" />
        </a>
      </div>

      <Typography variant="body2" color="text.secondary" align="center">
        {"© "}
        {new Date().getFullYear()}
        {" Purchase Paradise"}
      </Typography>
    </Box>
  );
}
