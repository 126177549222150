import axios from 'axios';
import { Auth } from 'aws-amplify';
import { config } from '../api/config';

const instance = axios.create({
  baseURL: config.apiUrl,
  headers: {
    'Content-Type': 'application/json'
  }
});

instance.interceptors.request.use(
  async config => {
    try {
      const session = await Auth.currentSession();
      const idToken = session.getIdToken().getJwtToken();
      config.headers.Authorization = `Bearer ${idToken}`;
    } catch (error) {
      console.log(error);
    }
    return config;
  },
  error => Promise.reject(error)
);

export default instance;
