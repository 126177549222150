import { createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";

const initialState = {
  idToken: null,
  accessToken: null,
  refreshToken: null,
  customerType: null,
  name: '',
  id: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess(state, action) {
      state.idToken = action.payload.idToken;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;

      // Decode the ID token and extract the custom:CustomerType attribute
      const decodedToken = jwtDecode(action.payload.idToken);
      state.customerType =
        decodedToken && decodedToken["cognito:groups"] && decodedToken["cognito:groups"].length
          ? decodedToken["cognito:groups"][0] === "CustomerType"
          : false;
      state.name = decodedToken && decodedToken["email"] ? extractBeforeAtSign(decodedToken["email"]) : '';
      state.id = decodedToken["cognito:username"];
    },
    logout(state) {
      state.idToken = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.customerType = null;
      state.id = null;
    },
  },
});

function extractBeforeAtSign(str) {
  let index = str.indexOf('@');
  if (index !== -1) {
      return str.substring(0, index);
  } else {
      return str;
  }
}

export const { loginSuccess, logout } = authSlice.actions;

export default authSlice.reducer;
