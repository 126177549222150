import React, { useState, useEffect } from "react";
import Cart from "./Cart";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { getPaymentIntent } from "../api/payments";
import { useSelector, useDispatch } from "react-redux";
import CartList from "./CartList";
import { updateShippingCost } from "../store/reducers/cartSlice";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Box,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import ShippingAddress from "./ShippingAddress";
import { getShippingCost } from "api/shipping";
import Spinner from "./Spinner";
import { useNavigate } from "react-router-dom";

const environment = process.env.NODE_ENV; // 'dev' or 'prod'
const testKey = "pk_test_51NaurBIoxGtsLS1a7voDcaYIIkQSGiiXmxExNH2exwi7nS4xCvUo8kN0uCVmvicdhFCNC7LVP1inHfRtB3TfLpy100spdfmzlW";
const liveKey = "pk_live_51NaurBIoxGtsLS1aXwQrShQUcmGBNIKDxhM5Z7f31Gvp2EWw8AF96SAxXnWp9himmEVo9r4o2r2d1dHA7EtpYtNc00KOLQKkJn";
const stripePromise = loadStripe(environment === 'production' ? liveKey : testKey);

const StyledStepper = styled(Stepper)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  fontSize: "30px",
}));

const CartWrapper = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector(state => state.auth.idToken);
  const address = useSelector(state => state?.address?.items);
  const [clientSecret, setClientSecret] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const cart = useSelector(state => state?.cart?.items);
  const theme = useTheme();

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  useEffect(() => {
    // Check if cart is empty
    if (Object.keys(cart).length === 0) {
      return;
    }

    const currentTotalPrice = Object.values(cart).reduce(
      (total, { item, quantity }) => total + item.Price * quantity,
      0
    );

    if (currentTotalPrice > 0 && totalPrice !== currentTotalPrice) {
      if (totalPrice === currentTotalPrice) {
        return;
      }
      setTotalPrice(currentTotalPrice);
    }
  }, [cart, totalPrice]);

  const fetchPaymentIntent = async cheapestPrice => {
    if (totalPrice > 0) {
      setLoading(1);
      const newTotalPrice = cheapestPrice > 0 ? Math.round((totalPrice + Math.round(cheapestPrice * 100) / 100) * 100) : totalPrice * 100;
      const data = await getPaymentIntent(newTotalPrice); // stripe is in cents
      setClientSecret(data.clientSecret);
      const stripe = await stripePromise; //wait for stripe to load
      setLoading(0);
    }
  };

  const fetchShippingCost = async () => {
    setLoading(1);
    let cheapestServicePrice = 0;
    const cartValues = Object.values(cart);
    if (cartValues.length > 0) {
      if (!address) {
        return;
      }
    } else {
      return;
    }
    let cartItems = [];

    // Loop over each item in the cart
    for (let key in cart) {
      const cartDetails = cart[key];

      if (cartDetails?.item?.FreeShipping !== 1) {
        // Create an object for the item
        let item = {
          name: address.name,
          street1: `${address.line1} ${address.line2}`,
          city: address.city,
          country: address.country,
          state: address.state,
          buyerEmailAddress: "",
          fromPostcode: "2000", // TODO: change to use postcode in cognito
          toPostcode: address.postal_code,
          length: cartDetails.item.Lengthcm,
          width: cartDetails.item.Widthcm,
          height: cartDetails.item.Heightcm,
          weight: cartDetails.item.Weightkg,
          freeShipping: cartDetails.item.FreeShippping,
          freeGiveAway: cartDetails.item.FreeGiveAway,
          freeGiveAwayGUID: cartDetails.item.FreeGiveAwayGUID
        };

        // Add the item object to the array
        cartItems.push(item);
      }
    }
    if (cartItems?.length > 0 && activeStep > 0) {
      const services = await getShippingCost(cartItems);
      const allServices = services[0].services;
      cheapestServicePrice = allServices.service.reduce((prev, curr) => {
        return parseFloat(prev.price) < parseFloat(curr.price)
          ? prev.price
          : curr.price;
      });
    }
    setShippingCost(cheapestServicePrice);
    dispatch(updateShippingCost({ shippingCost: cheapestServicePrice }));

    fetchPaymentIntent(cheapestServicePrice);
    setLoading(0);
  };

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    if (activeStep === 1) {
      fetchShippingCost();
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleContinueShopping = () => {
    navigate("/");
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const handleStepClick = step => {
    setActiveStep(step);
  };

  return (
    <div
      style={{
        paddingTop: "30px",
        width: "80%",
        margin: "auto",
        fontSize: "30px",
        color: theme.palette.secondary.main,
      }}
    >
      <StyledStepper activeStep={activeStep}>
        <Step>
          <StepLabel onClick={() => handleStepClick(0)}>Cart</StepLabel>
        </Step>
        <Step>
          <StepLabel onClick={() => handleStepClick(1)}>
            Shipping Address
          </StepLabel>
        </Step>
        <Step>
          <StepLabel onClick={() => handleStepClick(2)}>Payment</StepLabel>
        </Step>
      </StyledStepper>
      <Box sx={{ pt: 5 }}>
        {loading === 1 && <Spinner />}
        {activeStep === 0 && (
          <>
            <CartList />
            {isLoggedIn ? (
              <>
                <Button
                  onClick={handleNext}
                  sx={{ fontSize: { xs: 12, sm: 14, md: 20 }, marginRight: 5 }}
                >
                  Next&gt;&gt;
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={handleNext}
                  sx={{ fontSize: { xs: 12, sm: 14, md: 20 }, marginRight: 5 }}
                >
                  Continue as Guest&gt;&gt;
                </Button>
                <Button
                  onClick={handleLogin}
                  sx={{ fontSize: { xs: 12, sm: 14, md: 20 } }}
                >
                  Login
                </Button>
              </>
            )}
            <Box mt={2}>
              <Button
                onClick={handleContinueShopping}
                sx={{ fontSize: { xs: 12, sm: 14, md: 20 }, marginRight: 5 }}
              >
                Continue Shopping
              </Button>
            </Box>
          </>
        )}
        {activeStep === 1 && (
          <>
            <ShippingAddress />
            <Button
              onClick={handleBack}
              sx={{ fontSize: { xs: 12, sm: 14, md: 20 }, marginRight: 5 }}
            >
              &lt;&lt;Back
            </Button>
            <Button
              onClick={handleNext}
              sx={{ fontSize: { xs: 12, sm: 14, md: 20 }, marginRight: 5 }}
            >
              Next&gt;&gt;
            </Button>
            <Box mt={2}>
              <Button
                onClick={handleContinueShopping}
                sx={{ fontSize: { xs: 12, sm: 14, md: 20 }, marginRight: 5 }}
              >
                Continue Shopping
              </Button>
            </Box>
          </>
        )}
        {activeStep === 2 && (
          <>
            {clientSecret && (
              <Elements options={options} stripe={stripePromise}>
                <Typography color="primary">
                  Shipping and handling: {shippingCost > 0 ? '$'+shippingCost : 'Free'}
                </Typography>
                <Typography color="primary">
                  Total including shipping and handling: ${totalPrice}
                </Typography>
                <Cart />
              </Elements>
            )}
            <Button
              onClick={handleBack}
              sx={{ fontSize: { xs: 12, sm: 14, md: 20 }, marginRight: 5 }}
            >
              &lt;&lt;Back
            </Button>
            <Box mt={2}>
              <Button
                onClick={handleContinueShopping}
                sx={{ fontSize: { xs: 12, sm: 14, md: 20 }, marginRight: 5 }}
              >
                Continue Shopping
              </Button>
            </Box>
          </>
        )}
      </Box>
      <Box mt={10}>
        <img src="Secure_Payment_Badge.png" alt="Secure Payment Badge" width="128" height="128" style={{ marginRight: '10px' }} />
        <img src="Premium_Quality_Badge.png" alt="Premium Quality Badge" width="148" height="128" style={{ marginRight: '10px' }} />
        <img src="Fee_Shipping_Badge.png" alt="Free Shipping Badge" width="128" height="128" style={{ marginRight: '10px' }} />
        <img src="Money-back_Guarantee_Badge.png" alt="Money back guarantee" width="128" height="128" style={{ marginRight: '10px' }} />
      </Box>
      <Box mt={5}>
        <img src="stripe.png" alt="Secure Payment Badge" width="" height="" style={{ marginRight: '10px' }} />
      </Box>
    </div>
  );
};

export default CartWrapper;
