import React from 'react';

const FreeShipping = () => {
  return (
    <span style={{ marginRight: '5px', fontSize: '16px'}}>
      <span>Free Shipping (Australia only)</span>
    </span>
  );
};

export default FreeShipping;
