import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import Star from '@mui/icons-material/Star';



const testimonials = [
  {
    name: "Elizabeth Winter (Sydney)",
    testimonial: "PurchaseParadise.com.au is my go-to for all my tech needs. Their product range is impressive and the customer service is excellent. Highly recommended!"
  },
  {
    name: "Liam Turner (Sydney)",
    testimonial: "I bought a laptop from PurchaseParadise.com.au and it’s been fantastic. The delivery was prompt and the product was exactly as described. Great job!"
  },
  {
    name: "Sophie Dupont (Perth)",
    testimonial: "I love shopping at PurchaseParadise.com.au. Their website is user-friendly and the products are high-quality. I will definitely be a returning customer."
  },
  {
    name: "James Johnson (Woolongong)",
    testimonial: "I had a great experience shopping at PurchaseParadise.com.au. The prices were competitive and the delivery was fast. I couldn’t ask for more."
  },
  {
    name: "Nia Kofi (Brisbane)",
    testimonial: "I’m impressed with the service at PurchaseParadise.com.au. They have a wide range of products and the delivery was fast. I’ll definitely be shopping here again."
  },
  {
    name: "Aroha Taylor (Melbourne)",
    testimonial: "PurchaseParadise.com.au is a great online store. The prices are competitive and the customer service is excellent. Highly recommended."
  },
  {
    name: "Chen Wei (Sydney)",
    testimonial: "I recently bought a phone from PurchaseParadise.com.au and I’m very satisfied. The product is great and the delivery was on time. Excellent service!"
  },
  {
    name: "Ethan Williams (Townsville)",
    testimonial: "Shopping at PurchaseParadise.com.au was a breeze. Their website is easy to navigate and the product descriptions are detailed. Thumbs up!"
  },
  {
    name: "Isabella Rossi (Melbourne)",
    testimonial: "I had a wonderful shopping experience at PurchaseParadise.com.au. The prices are fair and the products are high-quality. I’ll definitely be back."
  },
  {
    name: "Emma Davis (Penrith)",
    testimonial: "I’m very happy with my purchase from PurchaseParadise.com.au. The product arrived on time and was exactly as described. Great job, team!"
  },
  {
    name: "Abdul Kamara (Brisbane)",
    testimonial: "I’m impressed with the service at PurchaseParadise.com.au. They have a wide range of products and the delivery was fast. I’ll definitely be shopping here again."
  },
  {
    name: "Tama Kingi (Darwin)",
    testimonial: "PurchaseParadise.com.au is a great online store. The prices are competitive and the customer service is excellent. Highly recommended."
  },
  {
    name: "Kim Jisoo (Adelaide)",
    testimonial: "I love shopping at PurchaseParadise.com.au. Their product range is extensive and the delivery is prompt. I will definitely be a returning customer."
  },
  {
    name: "Oliver Smith (Rockhampton)",
    testimonial: "I had a great experience shopping at PurchaseParadise.com.au. The website is user-friendly and the products are top-notch. I couldn’t ask for more."
  },
  {
    name: "Lucas Müller (Canberra)",
    testimonial: "I’m very satisfied with my purchase from PurchaseParadise.com.au. The product is great and the customer service is excellent. I’ll definitely be back."
  }
];

function Testimonials() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
      {testimonials.map((testimonial, index) => (
        <Card key={index} sx={{ m: 2, p: 2, width: '80%', bgcolor: 'secondary.main' }}>
          <CardContent>
            <Typography variant="h5" component="div" color="primary.main">
              {testimonial.name}          <div>
              {[...Array(5)].map((star, i) => (
                <Star key={i} />
              ))}
            </div>
            </Typography>
  
            <Typography variant="body2" color="primary.main">
              {testimonial.testimonial}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
}

export default Testimonials;
