import React, { useState, useEffect } from "react";
import { Amplify, Auth } from "aws-amplify";

const environment = process.env.NODE_ENV; // 'dev' or 'prod'

Amplify.configure({
  Auth: {
    region: "ap-southeast-2",
    userPoolId:  environment === 'production' ? "ap-southeast-2_y49Dg4njj" : "ap-southeast-2_OlIqlEELo",
    userPoolWebClientId: environment === 'production' ? "33r6p64uhfjnmuktp1vr1qh7pq" : "2rasnap4cm66j90n2rjoq3i3s9",
    oauth: {
      domain: environment === 'production' ? "cognito.purchaseparadise.com.au" : "cognito.apricotsoftware.com.au",
      scope: ["email", "openid", "phone"],
      redirectSignIn: environment === 'production' ? "https://purchaseparadise.com.au/shopping" : "http://localhost:3000/shopping",
      redirectSignOut: environment === 'production' ? "https://purchaseparadise.com.au" : "http://localhost:3000",
      responseType: "code",
      federationTarget: "COGNITO_USER_POOLS",
      // Facebook
      facebookAppId: "320681643641465",
      facebookRedirectSignIn: environment === 'production' ? "https://purchaseparadise.com.au" : "http://localhost:3000/shopping",
      facebookRedirectSignOut: environment === 'production' ? "https://purchaseparadise.com.au" : "http://localhost:3000/shopping",
      // Google
      googleClientId: "17593616210-bpt45njqcjergim6g5g0d9nire7i1lcp.apps.googleusercontent.com",
      googleRedirectSignIn: environment === 'production' ? "https://purchaseparadise.com.au" : "http://localhost:3000/shopping",
      googleRedirectSignOut: environment === 'production' ? "https://purchaseparadise.com.au" : "http://localhost:3000/shopping"
    },
  },
});

const Login = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    checkUser();
    signIn();
  }, []);

  const checkUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      setUser(user);
    } catch (err) {
      console.log(err);
    }
  };

  const signIn = async () => {
    try {
      await Auth.federatedSignIn();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>

    </div>
  );
};

export default Login;
