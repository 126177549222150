import React, { useEffect } from "react";

import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";

import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import Testimonials from "./components/Testimonials";
import Shopping from "./components/Shopping";
import Login from "./components/Login";
import Cart from "./components/Cart";
import CartWrapper from "./components/CartWrapper";
import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ExcelReader from "components/excelReader";
import PaymentResult from "components/PaymentResult";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import UserForm from "./components/UserForm";
import EditForm from "./components/EditForm";
import TermsAndConditions from "components/TermsAndConditions";
import ProductDetailCard from './components/ProductDetailCard';
import ReactGA from "react-ga4";


// Initialize React Ga with your tracking ID
ReactGA.initialize('G-7VBMBZKFKH');

const PageViewTracker = () => {
  const location = useLocation();

  useEffect(() => {
    // This line will track a pageview whenever the path changes
    ReactGA.send("pageview");
  }, [location]);

  return null;
}

// import theme from './Theme';

const theme = createTheme({
  palette: {
    primary: {
      main: "#0077be", // glossy blue
    },
    secondary: {
      main: "#f0f8ff", // light blue
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Router>
          <PageViewTracker />
          <Header />
          <div id="content">
            <main>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/product/:id" element={<ProductDetailCard />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/login" element={<Login />} />
                <Route path="/shopping" element={<Shopping />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/cartWrapper" element={<CartWrapper />} />
                <Route path="/upload" element={<ExcelReader />} />
                <Route path="/paymentResult" element={<PaymentResult />} />
                <Route path="/edit" element={<EditForm />} />
                <Route path="/editUser" element={<UserForm />} />
                <Route path="/termsConditions" element={<TermsAndConditions />} />
                <Route path="/testimonials" element={<Testimonials />} />
              </Routes>
            </main>
          </div>
          <Footer />
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
