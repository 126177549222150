import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: {}
};

const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    addToAddress(state, action) {
        state.items = action.payload;
    },
    removeFromAddress(state, action) {
      delete state.items;
    },
    emptyAddress(state) {
      state.items = {};
    }
  }
});

export const { addToAddress, removeFromAddress, emptyAddress } = addressSlice.actions;

export default addressSlice.reducer;
