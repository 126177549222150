import React from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { removeFromCart, updateQuantity } from "../store/reducers/cartSlice";
import {
  List,
  ListItem,
  ListItemText,
  Box,
  Grid,
  Typography,
  Tooltip,
  Divider,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ReactImageMagnify from "react-image-magnify";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useNavigate } from "react-router-dom";

const StyledGrid = styled(Grid)(({ theme }) => ({
  paddingTop: "26px",
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.primary.main,
  padding: theme.spacing(2),
}));

const CartList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cart = useSelector(state => state?.cart?.items);
  const location = useLocation();
  const totalPrice = Object.values(cart).reduce(
    (total, { item, quantity }) => total + item.Price * quantity,
    0
  );

  const handleCheckout = () => {
    navigate("/cartWrapper");
  };

  const handleRemoveFromCart = itemId => {
    dispatch(removeFromCart({ itemId }));
  };

  const handleUpdateQuantity = (itemId, quantity) => {
    dispatch(updateQuantity({ itemId, quantity }));
  };

  return (
    <StyledGrid container direction="column" spacing={1}>
      <Grid item>
        {location.pathname === "/cartWrapper" && (
          <List>
            {Object.values(cart).map(({ item, quantity }, index) => (
              <ListItem key={item.Id}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={2}>
                    {index === 0 && (
                      <Typography variant="subtitle1">Product</Typography>
                    )}
                    <ReactImageMagnify
                      {...{
                        smallImage: {
                          alt: "Product",
                          isFluidWidth: false,
                          isActivatedOnTouch: true,
                          src: item.MainImageUrl,
                          height: 60,
                          width: 60,
                        },
                        largeImage: {
                          src: item.MainImageUrl,
                          width: 600,
                          height: 1800,
                        },
                        enlargedImagePosition: "over",
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} md={5}>
                    {index === 0 && (
                      <Typography variant="subtitle1">Description</Typography>
                    )}
                    <ListItemText primary={item.ProductDescription} />
                  </Grid>
                  <Grid item xs={6} md={2}>
                    {index === 0 && (
                      <Typography variant="subtitle1">Price</Typography>
                    )}
                    <ListItemText
                      primary={`${item.Price.toLocaleString("en-AU", {
                        style: "currency",
                        currency: "AUD",
                      })}`}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={2}
                    container
                    direction="row"
                    alignItems="top"
                  >
                    {index === 0 && (
                      <Typography variant="subtitle1">Quantity</Typography>
                    )}
                    <Box
                      style={{ marginTop: "-12px" }}
                      minWidth="100%"
                      paddingRight="10px"
                    >
                      <Grid item container direction="row" xs={4}>
                        <ListItemText
                          style={{ marginTop: "-4px" }}
                          primary={`${quantity}  `}
                        />
                        <Tooltip title="Increase Quantity">
                          <AddIcon
                            onClick={() =>
                              handleUpdateQuantity(item.Id, quantity + 1)
                            }
                            sx={{ fontSize: 14, cursor: "pointer" }}
                          />
                        </Tooltip>
                        <Tooltip title="Reduce Quantity">
                          <RemoveIcon
                            onClick={() =>
                              handleUpdateQuantity(item.Id, quantity - 1)
                            }
                            sx={{ fontSize: 14, cursor: "pointer" }}
                          />
                        </Tooltip>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={1}>
                    {index === 0 && (
                      <Typography variant="subtitle1">Actions</Typography>
                    )}
                    <DeleteForeverIcon
                      onClick={() => handleRemoveFromCart(item.Id)}
                      sx={{ cursor: "pointer" }}
                    />
                  </Grid>
                  <Divider
                    sx={{
                      bgcolor: "secondary.main",
                      width: "100%",
                      marginTop: "7px",
                    }}
                  />
                </Grid>
              </ListItem>
            ))}
          </List>
        )}
        {location.pathname === "/" && ( //&& Object.values(cart).length > 0
          <Grid
            container
            spacing={2}
            direction="row"
            minWidth={100}
            xs={12}
            alignItems="center"
            paddingTop="20px"
            zIndex="99"
            minHeight={100}
          >
            <Box>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  pl: 3,
                  fontSize: 20,
                  font: "bold",
                  color: "primary.main",
                }}
              >
                Cart
              </Typography>
            </Box>
            {Object.values(cart).map(({ item, quantity }, index) => (
              <Grid
                key={item.Id}
                direction="row"
                alignItems="center"
                minWidth={100}
                xs={12}
                pt={2}
                minHeight={100}
              >
                <Grid item xs={12} alignItems="center" minHeight={100}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: 20 }}>
                      <ReactImageMagnify
                        {...{
                          smallImage: {
                            alt: "Product",
                            isFluidWidth: false,
                            isActivatedOnTouch: true,
                            src: item.MainImageUrl,
                            height: 70,
                            width: 70,
                          },
                          largeImage: {
                            src: item.MainImageUrl,
                            width: 600,
                            height: 1800,
                          },
                          enlargedImagePosition: "over",
                        }}
                      />
                    </Typography>
                  </Box>
                  <Typography sx={{ fontSize: 16, padding: 1 }}>
                    {item.Price?.toLocaleString("en-AU", {
                            style: "currency",
                            currency: "AUD",
                          })}
                  </Typography>
                </Grid>

                <Grid
                  item
                  container
                  direction="row"
                  xs={12}
                  sx={{ padding: 1 }}
                >
                  <Grid item direction="column" xs={8}>
                    Qty: {quantity}
                  </Grid>
                  <Grid item container direction="column" xs={3}>
                    <Tooltip title="Increase Quantity">
                      <AddIcon
                        onClick={() =>
                          handleUpdateQuantity(item.Id, quantity + 1)
                        }
                        sx={{ fontSize: 14, cursor: "pointer" }}
                      />
                    </Tooltip>
                    <Tooltip title="Reduce Quantity">
                      <RemoveIcon
                        onClick={() =>
                          handleUpdateQuantity(item.Id, quantity - 1)
                        }
                        sx={{ fontSize: 14, cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item direction="column" xs={1}>
                    <Tooltip title="Delete from cart">
                      <DeleteForeverIcon
                        onClick={() => handleRemoveFromCart(item.Id)}
                        sx={{
                          // paddingLeft: 1,
                          // alignItems: "right",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>

                <Divider
                  sx={{
                    bgcolor: "secondary.main",
                    width: "100%",
                    marginTop: "5px",
                    // marginBottom: "5px"
                  }}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
      <Grid item sx={{ paddingTop: "5px" }}>
        <Typography variant="h6">
          Total:{" "}
          {totalPrice.toLocaleString("en-AU", {
            style: "currency",
            currency: "AUD",
          })}
        </Typography>
      </Grid>
      {location.pathname === "/" && Object.values(cart).length > 0 && (
        <>
      <Button  style={{
                              backgroundColor: "#59c86b",
                              borderColor: "#59c86b",
                              color: "white",
                            }} variant="contained" onClick={handleCheckout}>
            Checkout
          </Button>
        </>
      )}
    </StyledGrid>
  );
};

export default CartList;
