import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: {},
  shippingCost: 0
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart(state, action) {
      const { item, quantity } = action.payload;
      if (state.items[item.Id]) {
        state.items[item.Id].quantity += quantity;
      } else {
        state.items[item.Id] = { item, quantity };
      }
    },
    removeFromCart(state, action) {
      delete state.items[action.payload.itemId];
    },
    updateQuantity(state, action) {
      const { itemId, quantity } = action.payload;
      if (state.items[itemId]) {
        state.items[itemId].quantity = quantity;
      }
    },
    updateShippingCost(state, action) {
      const { shippingCost } = action.payload;
      if (+shippingCost >= 0) {
        state.shippingCost = shippingCost;
      }
    },
    emptyCart(state) {
      state.items = {};
    }
  }
});

export const { addToCart, removeFromCart, updateQuantity, emptyCart, updateShippingCost } = cartSlice.actions;

export default cartSlice.reducer;
